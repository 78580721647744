.infoBoxWrapper {
  display: flex;
  align-items: center;
  gap: .5rem;
  width: auto;

  border-radius: 4px;
  padding: 0.25rem 0.5rem;

  > span {
    margin-top: 2px;
  }
}

.infoBoxInfo {
  border: 1px solid #17a2b8;
}

.infoBoxWarning {
  border: 1px solid #eddf5d;
}

.infoBoxError {
  border: 1px solid #ed0530;
}
