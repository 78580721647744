.containerTitle {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.tittle {
  padding-left: 16px !important;
  border-left: 4px solid #f34c36;
  font-weight: bold;
  color: white;
}

.custom-radio .form-check-input:checked {
  background-color: #ff9800;
  border-color: #ff9800;
}

.mt-4-custom {
  margin-top: 1rem; 
}

.mt-3-custom {
  margin-top: 5rem;
}