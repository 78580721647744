.main-container{
  position: relative;
}

.custom__span{
  color: blue;
  text-decoration: underline;
}

.custom__width{
  width: 320px;
}

.incidencia {
  background-color: #ffdadb;
}

.custom_button_width {
  min-width: 220px;
}
.wargning{
  color: red;
}
.wargning-box{
  padding: 0.8rem;
  margin: 0;
  width: max-content;
  border-radius: 4px;
  border: 2px solid red;
}
.over_screen_down_left{
  position:fixed;
  right: 1.5rem;
  bottom: 2rem;
}