.close ::before{
    content: 'X';
    visibility: visible;
    color: "black";
    font-weight: bold;
}

.sr-only::before{
    visibility: hidden;
}

.close{
    /*background-color: red; */
    visibility: hidden;
    position: absolute;
    right: 25px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-color: rgb(247, 12, 12, 0.5);
}

.btn_custom {
    background-color: #ff9800!important;
    border-color: #ff9800!important;
  }
  
  .btn_custom:hover {
    background-color:#E88A00!important;
    border-color: #E88A00!important;
  }

  .custom__margin {
    margin-top: 11px;
  }