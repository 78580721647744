.input {
    width: 100%;
    box-sizing: border-box;
  }

.input_number {
  max-width: 100px!important;
}

.modal_header {
  background-color: #ff9800
}

.btn_custom {
  background-color: #ff9800!important;
  border-color: #ff9800!important;
}

.btn_custom:hover {
  background-color:#E88A00!important;
  border-color: #E88A00!important;
}

.filter{
  margin-top:  20px;
  margin-bottom: 20px;
  margin-right: 400px;
  margin-left: 7%;
}