.containerTitle {
    padding: 0.9rem;
    background-color: #2c2c2c;
    display: flex;
    align-items: center;
  }


.tittle {
    padding-left: 16px !important;
    border-left: 4px solid #f34c36;
    font-weight: bold;
    color: white;
  }

.amount-summary{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.amount-summary h6{
  font-weight: bold;
}

.amount-summary h6 span{
  font-weight: 500;
}

.amount-summary__items {
  width: 185px;
  margin-left: 1rem;
}

.amount-summary__single-item:not(.amount-summary__items) {
  padding-right: 0.75rem;
}