.sage-notice {
  padding: 16px;
  border-radius: 8px;
  width: 500px;
  display: flex;
  align-items: center;
}

.sage-notice--success {
  border: 1px solid #28a745;
}

.sage-notice--error {
  border: 1px solid #EBD400;
}

.sage-notice__message {
  margin: 0;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
}

.sage-notice__icon {
  margin-right: 8px;
}

.sage-notice__icon--alert {
  color: #EBD400;
}

.sage-notice__icon--success {
  color: #28a745;
}