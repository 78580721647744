:root {
  --orange-flexicar-color: #ff5d0e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.error {
  color: #ba3939;
  font-size: 0.8rem;
  margin-left: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* keep footer in bottom */

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding: 0px 0px 6rem 0px !important;
  min-height: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  text-align: justify !important;
}

td {
  text-align: center;
  vertical-align: middle;
}

tr {
  text-align: center;
  vertical-align: middle;
}

.grey__container {
  background-color: rgba(212, 209, 209, 0.7);
  padding: 30px;
  border-radius: 5px;
  color: var(--dark-blue-color);
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.custom__navbar__spacer {
  height: 130px;
}

.custom__spacer {
  height: 300px;
}

.btn_custom {
  background-color: #ff9800!important;
  border-color: #ff9800!important;
}

.btn_custom:hover {
  background-color:#E88A00!important;
  border-color: #E88A00!important;
}

@media (max-width: 400px) {
  .footer {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
  }

  .custom__spacer {
    height: 20px;
  }
}


.cursor_pointer {
  cursor: pointer;
}