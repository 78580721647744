 .nav-area {
    display: flex;
    align-items: center;
    max-width: 100;
    padding: 5px 10px;
    background-color: rgba(248,249,250)!important;
  }
  
  .navbar-brand {
    align-items: center;
    width: 100%;
    float: right;
    display: block;
  }

  .logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
  }

  .ul{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menus {
    list-style: none;
    margin-top: 30px;
    float: right;
    display: flex;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    color: #fe9800!important;
    
  }
  
  .menu-items a {
    display: block;
    color: inherit;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    color: #fe9800!important;
    
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #f2f2f2;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown-navbar {
    position: absolute;
    right: auto;
    left: 0;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown-navbar.show {
    display: block;
  }
  
  .dropdown-navbar .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }
  
  /* content */
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content h2 {
    margin-bottom: 1rem;
  }
  
  .content a {
    color: #cc3852;
    margin-right: 10px;
  } 