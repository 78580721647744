:root {
  --orange-flexicar-color: #ff5d0e;
}


.custom__input {
  min-width: 300px;
}

.link__style {
  color: var(--orange-flexicar-color);
  text-decoration: none;
}

.link__style:hover {
  color: var(--orange-flexicar-color);
  font-weight: bold;
  text-decoration: none;
}

.custom__button {
  background-color: var(--orange-flexicar-color);
  border-color: var(--orange-flexicar-color);
}

.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8% auto 5%;
  padding: 50px 10px;
  border-radius: 15px;
  width: 100%;
  border: #ff5d0e;
}

button.btn.btn-warning {
  background-color: var(--orange-flexicar-color);
  color: aliceblue;
}

button.btn.btn-warning:hover {
  background-color: #f45000;
}

.centered {
  position: absolute;
  top: 30%;
  left: 37%;
}


@media only screen and (max-width: 992px) {
  .centered {
    position: absolute;
    top: 40%;
    left: 25%;
  }
}

@media only screen and (max-width: 576px) {
  .centered {
    position: absolute;
    top: 30%;
    left: 15%;
  }
}
