.containerTitle {
    padding: 0.9rem;
    background-color: #2c2c2c;
    display: flex;
    align-items: center;
}


.tittle {
    padding-left: 16px !important;
    border-left: 4px solid #f34c36;
    font-weight: bold;
    color: white;
}

.custom_header_table{
    font-weight: bold;
    color: black;
    font-size: 16px;
}
.input__id {
    min-width: 20px;
}

.input__society {
    min-width: 150px;
}
.input__titular {
    min-width: 300px;
}
.input__iban {
    min-width: 230px;
}
.input__concepto {
    min-width: 230px;
}
.input__importe {
    width:100px;
}

.custom__line {
    margin-top: 15px;
    color: black;
    background-color: black;
    height: 2px;
}

.custom__header{
    background-color: #E88A00;
    min-height: 40px;

}

.disable__component {
    pointer-events: "none"; 
    opacity: "0.4"
}

.center {
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
  }

  .custom__space {
      margin-top: 150px;
  }