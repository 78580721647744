cursor_pointer {
    cursor: pointer;
  }

.no__margin {
  margin: 0px!important;
  padding: 0px!important;
}

.pagination__container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__element{
  padding: 5px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination__text {
  margin: 0px;
}
.custom__width__date {
  width: 90px;
}

.state_custom_width{
  width: 160px;
}

.pago_custom_width{
  width: 140px;
}


.custom_payment_width{
  width: 120px;
}

.custom_navlink{
  color: black!important;
  text-decoration: none;
} 

.custom_th_width {
  width: 280px;
}



.owner_custom_width {
  width: 200px;
}

.date_custom_width {
  width: 100px;
}

.importe_custom_width {
  width: 130px;
}

.custom_payment_width{
  width: 130px;
}

.containerTitle {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}


.tittle {
  padding-left: 16px !important;
  border-left: 4px solid #f34c36;
  font-weight: bold;
  color: white;
}

.amount-summary{
display: flex;
flex-direction: row;
justify-content: flex-end;
}

.amount-summary h6{
font-weight: bold;
}

.amount-summary h6 span{
font-weight: 500;
}

.amount-summary__items {
width: 185px;
margin-left: 1rem;
}

.amount-summary__single-item:not(.amount-summary__items) {
padding-right: 0.75rem;
}


/* .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #fcb353;
} */