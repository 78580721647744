.cursor_pointer {
    cursor: pointer;
  }

.no__margin {
  margin: 0px!important;
  padding: 0px!important;
}

.pagination__container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__element{
  padding: 5px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination__text {
  margin: 0px;
}
.custom__width__date {
  min-width: 90px;
}

.state_custom_width{
  min-width: 160px;
}

.pago_custom_width{
  min-width: 140px;
}

.custom_domain_width{
  width: 150px;
}

.custom_navlink{
  color: black!important;
  text-decoration: none;
} 

.custom_th_width {
  min-width: 280px;
}

.owner_custom_width {
  min-width: 200px;
}

.date_custom_width {
  min-width: 100px;
}

.importe_custom_width {
  min-width: 130px;
}

.success {
  color: green;
}

/* .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #fcb353;
} */
