body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-1s2u09g-control:hover {
    border-color: transparent;
}
.css-1s2u09g-control{
    border-color: transparent;
}
i.material-icons.md-24.md-dark {
    color: #fff;
}
.toast-link{
  text-decoration: none !important;
  color: black;
  font-weight: 800;
    
}
.toast-title{
  color: black;
  font-weight: bold;
  font-size: 0.7rem
}
.toast-text{
  font-size: 0.6rem
}
td {
  font-size: 0.8rem;
}