.cursor_pointer {
    cursor: pointer;
  }
  
 .no__margin {
  margin: 0px!important;
  padding: 0px!important;
 }
  
 .pagination__container{
  display: flex;
  justify-content: center;
  align-items: center;
 }
  
 .pagination__element{
  padding: 5px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 }
  
 .pagination__text {
  margin: 0px;
 }
 .custom__width__date {
  width: 90px;
 }
  
 .state_custom_width{
  width: 160px;
 }
  
 .pago_custom_width{
  width: 140px;
 }
  

  
 .custom_payment_width{
  width: 120;
 }
  
 .custom_navlink{
  color: black!important;
  text-decoration: none;
 }
  
 .custom_th_width {
  width: 280px;
 }
  
  
  
 .owner_custom_width {
  width: 200px;
 }
  
 .date_custom_width {
  width: 100px;
 }
  
 .importe_custom_width {
  width: 130px;
 }
  
 .custom_payment_width{
  width: 130px;
 }
  